import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getQuizChallenge } from '../network';
import { getQueryParams } from '../utils/index';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function QuizLeagueTable() {
  const gridRef = useRef();

  const [quizName, setQuizName] = useState();
  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState();

  const defaultColDef = {
    sortable: true,
    resizable: true,
    width: 60,
    type: 'numericColumn',
    suppressFieldDotNotation: 'true',
  };

  useEffect(() => {
    setColumnDefs([
      {
        field: 'user.name', headerName: 'Name', type: 'leftAligned',
      },
      { field: 'quizResults.numQuestionsCorrect', headerName: 'F' },
      { field: 'quizResults.numQuestionsIncorrect', headerName: 'A' },
      {
        field: 'user.favouriteTeam', headerName: 'Team', width: 210, type: 'leftAligned',
      },
      {
        field: 'user.country', headerName: 'Country', width: 90, type: 'leftAligned',
      },
    ]);

    const { quizId } = getQueryParams();

    (async () => {
      try {
        const leagueTableData = await getQuizChallenge(quizId);
        setQuizName(leagueTableData.name);
        setRowData(leagueTableData.leagueTable);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="App-body">
      <header className="App-header">
        <h1>{quizName}</h1>
      </header>
      <div className="ag-theme-alpine h-100">
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
}
