import React from 'react';

export default function Footer() {
  return (
    <footer className="app-footer container justify-content-center ">
      <nav className="nav">
        <a className="nav-link" href="/">Home</a>
        <a className="nav-link" href="/privacy-policy">Privay Policy</a>
        <a className="nav-link" href="/support">Support</a>
      </nav>
    </footer>
  );
}
