import React from 'react';

export default function Header() {
  return (
    <header className="app-header">
      <div className="container d-flex justify-content-center text-justify">
        <a href="/" className="text-decoration-none text-reset text-center">
          <h1>English Football Quiz</h1>
        </a>
      </div>
    </header>
  );
}
