import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Home from './components/Home';
import LeagueTable from './components/LeagueTable';
import QuizLeagueTable from './components/QuizLeagueTable';
import SeasonLeagueTable from './components/SeasonLeagueTable';
import PrivacyPolicy from './components/PrivacyPolicy';
import Support from './components/Support';
import Footer from './components/Footer';
import Header from './components/Header';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  const { pathname } = useLocation();
  const isFromApp = (pathname === '/leaguetable' || pathname === '/seasonleaguetable');

  return (
    <div className="app">
      {!isFromApp && (
        <Header />
      )}
      <div className="app-body">
        <div className={classnames({
          'app-body': true,
          container: !isFromApp,
          'my-4': !isFromApp,
        })}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="quizleaguetable" element={<QuizLeagueTable />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="support" element={<Support />} />
            <Route path="support/delete/:userId" element={<Support />} />
            <Route path="leaguetable" element={<LeagueTable />} />
            <Route path="seasonleaguetable" element={<SeasonLeagueTable />} />
          </Routes>
        </div>
        {!isFromApp && (
        <Footer />
        )}
      </div>
    </div>
  );
}
