/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import emailjs from '@emailjs/browser';

export default function Support() {
  const { userId } = useParams();
  const form = useRef();

  const [subject, setSubject] = useState(userId ? `Delete User: ${userId}` : '');
  const [emailResponse, setEmailResponse] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    setEmailResponse('Sending...');
    emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE, 'template_hrh79jv', form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then(() => {
        setEmailResponse('Thank you, email sent.');
      }, (error) => {
        setEmailResponse(error.text);
      });
  };

  return (
    <div style={{ maxWidth: 600 }}>
      <h2>Contact the team</h2>
      <form ref={form} onSubmit={sendEmail} className="d-flex flex-column email-form mx-auto" style={{ maxWidth: 600 }}>
        <label>Subject</label>
        <input type="text" name="subject" style={{ maxWidth: 200 }} value={subject} onChange={(e) => setSubject(e.target.value)} />
        <label>Reply Email</label>
        <input type="email" name="email" style={{ maxWidth: 400 }} />
        <label>Message</label>
        <textarea name="message" style={{ maxWidth: 600, height: 200 }} />
        <input type="submit" value="Send" className="align-self-end" style={{ width: 150 }} disabled={emailResponse} />
      </form>
      <span>{emailResponse}</span>
    </div>
  );
}
