import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getLeagueTable } from '../network';
import { getQueryParams } from '../utils/index';
import BadgeCell from './BadgeCell';
import FlagCell from './FlagCell';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function LeagueTable() {
  const gridRef = useRef();

  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState();

  const defaultColDef = {
    sortable: true,
    resizable: true,
    suppressFieldDotNotation: 'true',
    wrapHeaderText: true,
  };

  useEffect(() => {
    setColumnDefs([
      { field: 'user.name', headerName: 'Name', pinned: 'left' },
      {
        field: 'leagueTableResults.position', headerName: 'Pos', type: 'numericColumn', width: 60,
      },
      { field: 'leagueTableResults.score', headerName: 'Points', type: 'numericColumn' },
      { field: 'quizResults.numQuizzes', headerName: 'Quizzes', type: 'numericColumn' },
      {
        field: 'quizResults.numQuizzesPassed', headerName: 'Quizzes Passed', type: 'numericColumn', width: 100,
      },
      {
        field: 'quizResults.numQuizzesFailed', headerName: 'Quizzes Failed', type: 'numericColumn', width: 100,
      },
      {
        field: 'quizResults.numQuestionsCorrect', headerName: 'Questions Right', type: 'numericColumn', width: 100,
      },
      {
        field: 'quizResults.numQuestionsIncorrect', headerName: 'Questions Wrong', type: 'numericColumn', width: 100,
      },
      {
        field: 'quizResults.numFeatureYellowCardUsed', headerName: 'Yellow Cards', type: 'numericColumn', width: 100,
      },
      {
        field: 'quizResults.numFeatureRedCardUsed', headerName: 'Red Cards', type: 'numericColumn', width: 100,
      },
      { field: 'user.team', headerName: 'Team', cellRenderer: BadgeCell },
      { field: 'user.country', headerName: 'Country', cellRenderer: FlagCell },
    ]);

    const {
      date, endDate, team, country,
    } = getQueryParams();

    const leagueTableParams = {
      ...date && { date },
      ...endDate && { endDate },
      ...team && { team },
      ...country && { country },
    };

    (async () => {
      try {
        const leagueTableData = await getLeagueTable(leagueTableParams);
        setRowData(leagueTableData.filter((l) => l.quizResults.numQuizzes > 0));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function onFirstDataRendered(params) {
    window.setTimeout(() => {
      const colIds = [];
      gridRef.current.columnApi.getColumns().forEach((column) => {
        if (!(column.getId() === 'quizResults.numQuizzesPassed'
            || column.getId() === 'quizResults.numQuizzesFailed'
            || column.getId() === 'quizResults.numQuestionsCorrect'
            || column.getId() === 'quizResults.numQuestionsIncorrect'
            || column.getId() === 'quizResults.numFeatureYellowCardUsed'
            || column.getId() === 'quizResults.numFeatureRedCardUsed')) {
          colIds.push(column.getId());
        }
      });
      params.columnApi.autoSizeColumns(colIds);
    }, 0);
  }

  return (
    <div className="d-flex h-100">
      <div className="ag-theme-alpine grid-container">
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          onFirstDataRendered={(params) => onFirstDataRendered(params)}
          suppressColumnVirtualisation
        />
      </div>
    </div>
  );
}
