import React from 'react';
import LeagueTable from './LeagueTable';

export default function Home() {
  return (
    <div>
      <div className="d-flex justify-content-center gap-4 mb-5">
        <a href="https://apps.apple.com/us/app/english-football-quiz-online/id1628535894">
          <img src="/AppStore.png" alt="App Store" className="store-images img-fluid" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.hinnok.englishfootballquiz">
          <img src="/GooglePlay.png" alt="Play Store" className="store-images img-fluid" />
        </a>
      </div>
      <h2 className="text-center mb-4">League Table</h2>
      <LeagueTable />
    </div>
  );
}
