const apiUrl = process.env.REACT_APP_API_URL;

export async function getLeagueTable(leagueTableParams) {
  try {
    const response = await fetch(`${apiUrl}v1/LeagueTable`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(leagueTableParams) });
    if (response.status === 200) {
      return await response.json();
    }
    console.log(await response.json());
  } catch (error) {
    console.log(error);
  }
  return undefined;
}

export async function getQuizChallenge(quizId) {
  const url = new URL(`${apiUrl}v1/LeagueTable`);
  url.searchParams.set('quizId', quizId);

  try {
    const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    if (response.status === 200) {
      return await response.json();
    }
    console.log(await response.json());
  } catch (error) {
    console.log(error);
  }
  return undefined;
}

export async function getSeasonResults(seasonResultParams) {
  try {
    const response = await fetch(`${apiUrl}v1/SeasonResults`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(seasonResultParams) });
    if (response.status === 200) {
      return await response.json();
    }
    console.log(await response.json());
  } catch (error) {
    console.log(error);
  }
  return undefined;
}
