/* eslint-disable import/prefer-default-export */
export function getQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {},
  );

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
}
